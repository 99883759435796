import Utils from './utils.js';

class TickerHelper {
  constructor(el) {
    this.el = el;
    this.tickerContentEl = this.el.querySelector('.ticker__content');
    this.tickerTextEls = this.tickerContentEl.querySelectorAll('.ticker__text');
    this.childrenCount = this.tickerTextEls.length;
    this.lastWindowWidth = window.innerWidth;

    this.styleTicker = null;
    this.baseSpeed = undefined;

    this._init();

    const debouncedResizeHandler = Utils.debounce((e) => {
      if(window.innerWidth != this.lastWindowWidth)
        this._init();

      this.lastWindowWidth = window.innerWidth;
    }, 50);
    window.addEventListener('resize', debouncedResizeHandler);
  }

  _init() {
    this.baseSpeed = Utils.isMobileView() ? 8 : 17;

    if(this.styleTicker != null)
      this.styleTicker.remove();

    const id = this.el.getAttribute('id');
    const sizeContent = this.tickerTextEls[0].getBoundingClientRect().width;
    const speed = this.baseSpeed * sizeContent / window.innerWidth;

    this.tickerContentEl.style.width = (sizeContent * this.childrenCount) + 'px';

    const style = document.createElement('style');
    style.type = 'text/css';
    const keyFrames = `
      @keyframes ticker-move-${id} {
        0% {
          transform: translateX(0px);
        }
        100% {
          transform: translateX(calc(-1 * A_DYNAMIC_VALUE ) );
        }
      }`;
    style.innerHTML = keyFrames.replace(/A_DYNAMIC_VALUE/g, sizeContent+'px');
    this.styleTicker = document.getElementsByTagName('head')[0].appendChild(style);

    this.tickerTextEls.forEach(el => {
      el.classList.remove('ticker__text--move');

      el.style.animationName = 'ticker-move-'+ id;
      el.style.animationDuration = speed +'s';

      el.classList.add('ticker__text--move')
    });

  }
};

export default TickerHelper;
