import Utils from '../helpers/utils.js';

export default class CaseStudiesPageController {
  constructor(options) {
    this.activeFilters = [];

    this.el = document.querySelector('.section--case-studies');
    this.filtersEls = document.querySelectorAll('[data-filter-case-studies-by]');
    this.caseStudyCardEls = this.el.querySelectorAll('[data-case-study-cat]');
    this.floatingFiltersTriggerEl = document.querySelector('.floating-filters-trigger');

    //Handle Filters
    this.filtersEls.forEach(el => {
      el.addEventListener('click', () => {
        const filter = el.getAttribute('data-filter-case-studies-by');

        //Handle filter state
        document.querySelectorAll('[data-filter-case-studies-by="'+filter+'"]').forEach(el => el.classList.toggle('filter-case-studies--active'));

        if(el.classList.contains('filter-case-studies--active'))
          this.activeFilters.push(filter);
        else
          this.activeFilters = this.activeFilters.filter(value => value != filter);

        //Show/Hide case studies
        if(this.activeFilters.length == 0) {
          this.caseStudyCardEls.forEach(el => el.classList.remove('case-study-card--hidden'));
        }
        else {
          this.caseStudyCardEls.forEach(el => {
            const categories = el.getAttribute('data-case-study-cat').split(',');

            if(this.activeFilters.some(value => categories.includes(value)))
              el.classList.remove('case-study-card--hidden');
            else
              el.classList.add('case-study-card--hidden');
          });
        }

        //Scroll to first case study if none is visible
        if(el.closest('.floating-filters') && !Utils.elementIsVisibleInViewport(this.el))
          document.documentElement.scrollTo({top: this.el.querySelectorAll('.case-study-card:not(.case-study-card--hidden)')[0].getBoundingClientRect().top + 1 + document.documentElement.scrollTop });
      })
    });

    //Show/Hide floating filters
    if(this.floatingFiltersTriggerEl) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            document.querySelector('.case-studies').classList.remove('show-floating-filters');
            return;
          }

          if (entry.boundingClientRect.top < 0)
            document.querySelector('.case-studies').classList.add('show-floating-filters');
        });
      }, {
        root: null,
        threshold: 0,
      });
      observer.observe(this.floatingFiltersTriggerEl);
    }
  }
}
