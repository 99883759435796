import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginIndicator} from "scrollmagic-plugins";
import anime from 'animejs/lib/anime.es';

import Utils from '../helpers/utils.js';

export default class HomePageController {
  constructor(options) {
    this.options = options || null;

    // Init scroll magic indicators plugin if debug flag is set
    if(this.options?.debug)
      ScrollMagicPluginIndicator(ScrollMagic);

    this.headerEl = document.querySelector('.header');
    this.heroLogoContainerEl = document.querySelector('.hero-home__logo');
    this.heroLogoRefEl = this.heroLogoContainerEl.querySelector('svg');
    this.heroLogoEl = this.heroLogoContainerEl.querySelector('.hero-home__logo-animated');
    this.heroTheDeepEndEl = document.querySelector('.hero-home__the-deep-end');

    this.padding = (window.innerWidth - this.headerEl.getBoundingClientRect().width) / 2;

    this.animations = {
      logo: {
        anim: undefined,
        params: {
          targets: this.heroLogoEl,
          width: () => {
            if(Utils.isMobileView())
              return [(window.innerWidth - this.padding*2) * 1, window.innerWidth * 0.5];
            else
              return [(window.innerWidth - this.padding*2) * 0.9, window.innerWidth * 0.3];
          },
          easing: 'linear',
          autoplay: false,
        }
      },
      logoContainer: {
        anim: undefined,
        params: {
          targets: this.heroLogoContainerEl,
          marginTop: () => {
            if(Utils.isMobileView())
              return ['10px', '0'];
            else
              return ['1.85rem', '0rem'];
          },
          easing: 'linear',
          autoplay: false,
        }
      },
      theDeepEnd: {
        anim: undefined,
        params: {
          targets: this.heroTheDeepEndEl,
          width: ['20%', '10%'],
          easing: 'linear',
          autoplay: false,
        }
      },
    };

    this.smController = new ScrollMagic.Controller();

    this.proportionLogo = this.heroLogoRefEl.getBoundingClientRect().height / this.heroLogoRefEl.getBoundingClientRect().width;
    this.padding = (window.innerWidth - this.headerEl.getBoundingClientRect().width) / 2;
    this.widthLogoMax = Utils.isMobileView() ? (window.innerWidth - this.padding*2) * 1 : (window.innerWidth - this.padding*2) * 0.9;
    this.widthLogoMin = Utils.isMobileView() ? window.innerWidth * 0.5 : window.innerWidth * 0.3;

    this.duration = this.widthLogoMax * this.proportionLogo - this.widthLogoMin * this.proportionLogo;

    this._initAnimation('logo');
    this._initAnimation('logoContainer');
    this._initAnimation('theDeepEnd');

    this.smScene = new ScrollMagic.Scene({
      triggerHook: 'onLeave',
      duration: this.duration
    }).addTo(this.smController);

    // Add debug indicators to scene if debug flag is set
    if(this.options?.debug)
      this.smScene.addIndicators();

    this.smScene.on('progress', event => {
      this.animations.logo.anim.seek(this.animations.logo.anim.duration * event.progress);
      this.animations.logoContainer.anim.seek(this.animations.logoContainer.anim.duration * event.progress);
      this.animations.theDeepEnd.anim.seek(this.animations.theDeepEnd.anim.duration * event.progress);
    });

    this.smScene.on('enter', event => {
      if(event.scrollDirection == 'REVERSE')
        this.heroLogoEl.classList.remove('hero-home__logo-animated--fixed');
    });
    this.smScene.on('leave', event => {
      if(event.scrollDirection == 'FORWARD')
        this.heroLogoEl.classList.add('hero-home__logo-animated--fixed');
    });

    const debouncedResizeHandler = Utils.debounce((e) => {
      this.proportionLogo = this.heroLogoRefEl.getBoundingClientRect().height / this.heroLogoRefEl.getBoundingClientRect().width;
      this.padding = (window.innerWidth - this.headerEl.getBoundingClientRect().width) / 2;
      this.widthLogoMax = Utils.isMobileView() ? (window.innerWidth - this.padding*2) * 1 : (window.innerWidth - this.padding*2) * 0.9;
      this.widthLogoMin = Utils.isMobileView() ? (window.innerWidth - this.padding*2) * 0.5 : (window.innerWidth - this.padding*2) * 0.3;

      this.duration = this.widthLogoMax * this.proportionLogo - this.widthLogoMin * this.proportionLogo;

      this._initAnimation('logo');
      this._initAnimation('logoContainer');
      this._initAnimation('theDeepEnd');

      this.smScene.duration(this.duration);

      const currentProgress = this.smScene.progress();
      this.animations.logo.anim.seek(this.animations.logo.anim.duration * currentProgress);
      this.animations.logoContainer.anim.seek(this.animations.logoContainer.anim.duration * currentProgress);
      this.animations.theDeepEnd.anim.seek(this.animations.theDeepEnd.anim.duration * currentProgress);
    }, 50);
    window.addEventListener('resize', debouncedResizeHandler);
  }

  _initAnimation(animName) {
    this.animations[animName].anim = anime(this.animations[animName].params);
  }
}
