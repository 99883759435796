import Utils from '../helpers/utils.js';

export default class WavyUnderline {
  constructor(el, index) {
    this.el = el;
    this.index = index;

    this.gapOfDash = 10;
    this.strokeWidth = 1;
    this.speedBase = 3000;

    this.lastWindowWidth = window.innerWidth;

    this.linkBoundRect = undefined;
    this.spanEl = undefined;
    this.pathEl = undefined;
    this.pathWidth = undefined;
    this.pathTotalLength = undefined;

    document.fonts.ready.then(() => {
      this._init();
    });

    const debouncedResizeHandler = Utils.debounce((e) => {
      if(window.innerWidth != this.lastWindowWidth) {
        this._reset();
        this._init();
      }

      this.lastWindowWidth = window.innerWidth;
    }, 50);
    window.addEventListener('resize', debouncedResizeHandler);
  }

  _reset() {
    this.spanEl.remove();
  }

  _init() {
    this._drawPath();

    this._addHoverAnimation();

    this._addOverflowPath();

    this.el.classList.add('link--with-wave-underline--ready');
  }

  _addHoverAnimation() {
    const style = document.createElement('style');
    style.type = 'text/css';

    const speedFor100px = this.speedBase;

    let newCss = `
      [data-wavy-underline] path#link-underline-wavy--${this.index},
      a.link--with-wave-underline path#link-underline-wavy--${this.index} {
        stroke-dasharray: 0 GAP_VALUE WIDTH_VALUE GAP_VALUE;
      }
      [data-wavy-underline]:hover path#link-underline-wavy--${this.index},
      a.link--with-wave-underline:hover path#link-underline-wavy--${this.index} {
        animation: link-underline-wavy--${this.index} SPEED_VALUE linear infinite;
      }

      @keyframes link-underline-wavy--${this.index} {
        0% {
          stroke-dashoffset: 0;
          transform: translateX(0px);
        }
        100% {
          stroke-dashoffset: calc(-1 * DASH_OFFSET_VALUE);
          transform: translateX(calc(-1 * TRANSLATE_X_VALUE ) );
        }
      }`;
    newCss = newCss.replace(/GAP_VALUE/g, this.gapOfDash+'px');
    newCss = newCss.replace(/DASH_OFFSET_VALUE/g, (this.pathTotalLength - this.strokeWidth) +'px');
    newCss = newCss.replace(/WIDTH_VALUE/g, (this.pathTotalLength - this.gapOfDash*2 - this.strokeWidth) +'px');
    newCss = newCss.replace(/TRANSLATE_X_VALUE/g, (this.pathWidth + this.gapOfDash) +'px');
    newCss = newCss.replace(/SPEED_VALUE/g, (this.pathWidth * speedFor100px / 100) +'ms');

    style.innerHTML = newCss;
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  _addOverflowPath() {
    this._buildCurvyPath(this.pathEl, this.linkBoundRect.width, this.linkBoundRect.height, 0.3, 3);
  }

  _drawPath() {
    this.linkBoundRect = this.el.getBoundingClientRect();

    this.el.insertAdjacentHTML('beforeend', `<span class="link-wave-underline"><svg xmlns="http://www.w3.org/2000/svg" viewBox="${this.gapOfDash/2} 0 ${this.linkBoundRect.width} ${this.linkBoundRect.height *0.3}" width="${this.linkBoundRect.width}px" height="${this.linkBoundRect.height *0.3}px"><path fill="none" stroke="#06cad5" stroke-width="${this.strokeWidth}" stroke-linecap="round"></path></svg></span>`);

    this.spanEl = this.el.querySelector('span');
    this.pathEl = this.spanEl.querySelector('path');

    //return if element is not drawn in dom
    if(this.linkBoundRect.width == 0)
      return;

    this._buildCurvyPath(this.pathEl, this.linkBoundRect.width, this.linkBoundRect.height, 0.3);

    this.el.classList.add('link--with-wave-underline');
    this.pathEl.setAttribute('id', 'link-underline-wavy--'+ this.index);

    //update variables
    this.pathWidth = this.pathEl.getBoundingClientRect().width - this.gapOfDash;
    this.pathTotalLength = this.pathEl.getTotalLength();

    //update svg width
    this.el.querySelector('svg').setAttribute('viewBox', `${this.gapOfDash/2} 0 ${this.pathWidth} ${this.linkBoundRect.height *0.3}`);
    this.el.querySelector('svg').setAttribute('width', `${this.pathWidth}px`);
  }

  _buildCurvyPath(element, w, h, ampl, multiply = 1) {
    let m = 0.512286623256592433;

    let curveWidth = (0.25 * h) *2;
    let rightNumOfCurves =  Math.round(w / curveWidth) - 1 + 1;// - 1 : curve created with pathDataInit and pathDataEnd, + 1 to fix the shorter underline created because of dasharray
    let numOfCurves =  rightNumOfCurves * multiply;

    let newPath = [];
    const firstPointX = window.devicePixelRatio > 1 ? 0 : -2;

    //represent half a curve
    let pathDataInit = [
      'M', firstPointX, (h * ampl)/2,
      'M', 0, (h * ampl)/2,
      'c',  0.25 * h * m, 0,
      0.25 * h * (1 - m), -0.25 * h * ampl,
      0.25 * h, -0.25 * h * ampl,
    ];
    newPath = newPath.concat(pathDataInit);

    for(let i = 0; i < numOfCurves; i++) {
      let pathDataPart = [
        's', 0.25 * h * (1 - m), 0.25 * h * ampl,
        0.25 * h, 0.25 * h * ampl,
        's', 0.25 * h * (1 - m), -0.25 * h * ampl,
        0.25 * h, -0.25 * h * ampl,
      ];

      newPath = newPath.concat(pathDataPart);
    }

    //represent half a curve
    let pathDataEnd = [
      's', 0.25 * h * (1 - m), 0.25 * h * ampl,
      0.25 * h, 0.25 * h * ampl,
    ];

    newPath = newPath.concat(pathDataEnd);

    const newPathString = newPath.join(' ');

    element.setAttribute('d', newPathString);
  }
}
