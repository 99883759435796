import Utils from '../helpers/utils.js';

import Masonry from 'masonry-layout';

export default class TheDeepEndPageController {
  constructor() {
    this.gridEl = document.querySelector('.the-deep-end-grid');
    this.floatObjectsContainerEl = document.querySelector('.float-objects');

    this.objectsList = this._getObjectsList(document.querySelectorAll('[data-random-obj-src]'));
    this.randomObjects = [];

    this.numRandomObjects = 5;

    this._initGrid();
    this._initRandomObj();
    this._initRandomObjAnimations();
  }

  _initGrid() {
    const msnry = new Masonry(this.gridEl, {
      itemSelector: '.the-deep-end-grid__image',
      initLayout: false,
    });

    msnry.once('layoutComplete', () => {
      document.body.classList.add('msnry-loaded');
    });

    msnry.layout();
  }

  _initRandomObj() {
    for(let i = 0; i < this.numRandomObjects; i++) {
      let randomObj;
      do {
        randomObj = this.objectsList[Utils.randomNum(0, this.objectsList.length - 1)];
      } while(this.randomObjects.includes(randomObj));

      this.randomObjects.push(randomObj);

      const percentageWidth = parseInt(randomObj.width) > parseInt(randomObj.height) ? Utils.randomNum(25,40) : Utils.randomNum(15,30);

      const objectWidth = window.innerWidth * percentageWidth / 100;
      const objectHeight = randomObj.height * objectWidth / randomObj.width;

      const left = Utils.randomNum(0, document.querySelector('.page-content').offsetWidth - objectWidth);
      const top = Utils.randomNum(0, document.querySelector('.page-content').offsetHeight - objectHeight);

      this.floatObjectsContainerEl.insertAdjacentHTML('beforeend', `<div id="float-objects-image-${i}" class="float-objects__image floating-animation"><img style="width: ${percentageWidth}%;" src="${randomObj.src}" width="${randomObj.width}" height="${randomObj.height}"></div>`);
    }
  }


  _initRandomObjAnimations() {
    this.floatObjectsContainerEl.querySelectorAll('.float-objects__image').forEach(el => {
      const id = el.getAttribute('id');
      const elWidth = el.getBoundingClientRect().width;
      const elHeight = el.getBoundingClientRect().height;

      const style = document.createElement('style');
      style.type = 'text/css';

      let newCss = `
        #${id} {
          animation: float-obj-animation--${id} SPEED_VALUE linear infinite alternate;
        }

        @keyframes float-obj-animation--${id} {
          0% {
            transform: translate(TRANSLATE_X_INIT, TRANSLATE_Y_INIT);
          }
          100% {
            transform: translate(TRANSLATE_X_END, TRANSLATE_Y_END);
          }
        }`;

      let posXInit, posYInit, posXEnd, posYEnd;
      const startX = Utils.randomNum(0,1) > 0.5 ? 'left' : 'right';

      const pageHeight = document.body.offsetHeight - document.querySelector('.footer').offsetHeight;
      const extra = elWidth/6;

      if(startX == 'left') {
        posXInit = (-elWidth - extra);
        posXEnd = (window.innerWidth + extra);
      } else {
        posXInit = (window.innerWidth + extra);
        posXEnd = (-elWidth - extra);
      }

      posYInit = Utils.randomNum(-elHeight, pageHeight + elHeight);
      posYEnd = Utils.randomNum(-elHeight, pageHeight + elHeight);

      const speed = Math.abs(posYEnd - posYInit) > pageHeight/2 ? Utils.randomNum(150, 250) : Utils.randomNum(50, 150);

      newCss = newCss.replace(/TRANSLATE_X_INIT/g, posXInit+'px');
      newCss = newCss.replace(/TRANSLATE_Y_INIT/g, posYInit+'px');
      newCss = newCss.replace(/TRANSLATE_X_END/g,  posXEnd+'px');
      newCss = newCss.replace(/TRANSLATE_Y_END/g,  posYEnd+'px');
      newCss = newCss.replace(/SPEED_VALUE/g, speed +'s');

      style.innerHTML = newCss;
      document.getElementsByTagName('head')[0].appendChild(style);
    });
  }

  _getObjectsList(el) {
    return [...el].map(el => {
      return {
        src: el.getAttribute('data-random-obj-src'),
        width: el.getAttribute('data-random-obj-width'),
        height: el.getAttribute('data-random-obj-height'),
      }
    });
  }
}
